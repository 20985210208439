export default {
	"pages": {
		"root": {
			"name": "root",
			"children": [
				"65060e7b6efcea001ae6f2f5",
				"65060e7b6efcea001ae6f2f9",
				"65060e7b6efcea001ae6f2fd",
				"65060e7b6efcea001ae6f301",
				"65060e7b6efcea001ae6f309"
			],
			"id": "root",
			"pageUrl": "root"
		},
		"65060e7b6efcea001ae6f2f5": {
			"id": "65060e7b6efcea001ae6f2f5",
			"name": "404",
			"pageUrl": "404"
		},
		"65060e7b6efcea001ae6f2f9": {
			"id": "65060e7b6efcea001ae6f2f9",
			"name": "index",
			"pageUrl": "index",
			"seo": {
				"og:description": "It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.",
				"title": "Home | Website Example",
				"og:title": "Home | Website Example",
				"og:image": "https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z",
				"description": "It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."
			}
		},
		"65060e7b6efcea001ae6f2fd": {
			"id": "65060e7b6efcea001ae6f2fd",
			"pageUrl": "about",
			"name": "About",
			"seo": {
				"og:title": "About | Website Example",
				"og:description": "It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.",
				"description": "It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.",
				"og:image": "https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z",
				"title": "About | Website Example"
			}
		},
		"65060e7b6efcea001ae6f301": {
			"id": "65060e7b6efcea001ae6f301",
			"seo": {
				"og:image": "https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z",
				"title": "Work | Website Example",
				"og:title": "Work | Website Example",
				"description": "It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.",
				"og:description": "It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."
			},
			"pageUrl": "work",
			"name": "Services"
		},
		"65060e7b6efcea001ae6f309": {
			"id": "65060e7b6efcea001ae6f309",
			"pageUrl": "contact",
			"name": "Contact",
			"seo": {
				"og:image": "https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z",
				"title": "Contact | Website Example",
				"og:title": "Contact | Website Example",
				"description": "It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.",
				"og:description": "It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."
			}
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"display": "block"
		},
		"seo": {
			"title": "JPLLC Mining",
			"description": "JaouadPanorama, LLC website for all its services in the mining industry.",
			"robotsFileType": "autogenerated",
			"hide_for_se": true,
			"favicon_32px": "https://uploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13:53:24.519Z",
			"favicon_apple_152px": ""
		}
	}
}