import React from "react";
import { useOverrides, Override, SocialMedia } from "@quarkly/components";
import { Image, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"margin": "0 0 0 0",
	"padding": "16px 0 16px 0",
	"box-sizing": "border-box",
	"quarkly-title": "Footer",
	"justify-content": "center"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"grid-gap": "32px",
			"width": "100%",
			"grid-template-columns": "repeat(2, 1fr)",
			"sm-grid-gap": "16px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"sm-flex-wrap": "wrap"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13:53:24.519Z",
			"width": "28px",
			"height": "28px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"opacity": "0.6",
			"font": "--base",
			"color": "--dark",
			"text-align": "left",
			"margin": "1px 0px 0px 10px",
			"hover-text-decoration-line": "underline",
			"white-space": "nowrap",
			"href": "https://jaouadpanorama.com",
			"children": "Made By JaouadPanorama, LLC"
		}
	},
	"socialMedia": {
		"kind": "SocialMedia",
		"props": {
			"twitter": "https://twitter.com/quarklyapp",
			"youtube": "https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw",
			"justify-content": "flex-end",
			"facebook": "https://www.facebook.com/quarklyapp/"
		}
	},
	"socialMediaOverride": {
		"kind": "Override",
		"props": {
			"slot": "icon",
			"color": "--dark"
		}
	},
	"socialMediaOverride1": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"border-radius": "50%",
			"background": "none"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			width="100%"
			margin="0px 32px 0px 32px"
			md-margin="0px 16px 0px 16px"
			min-width="auto"
		/>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Image {...override("image")} />
				<Link {...override("link")} />
			</Box>
			<SocialMedia {...override("socialMedia")}>
				<Override {...override("socialMediaOverride")} />
				<Override {...override("socialMediaOverride1")} />
			</SocialMedia>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;