import React from "react";
import theme from "theme";
import { Theme, Link, Text, Image, Button, Section, Box, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
import { FaMountain, FaIndustry, FaLeaf, FaGlobe, FaTint, FaLightbulb } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | Website Example
			</title>
			<meta name={"description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:title"} content={"About | Website Example"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13:53:24.519Z"} type={"image/x-icon"} />
		</Helmet>
		<Section align-items="center" justify-content="center" padding="16px 0 16px 0" quarkly-title="NavHeader">
			<Override
				slot="SectionContent"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				display="grid"
			/>
			<Link
				href="/"
				position="relative"
				transition="opacity 200ms ease"
				quarkly-title="Link"
				display="flex"
				flex-direction="row-reverse"
				text-align="left"
			>
				<Text
					justify-content="flex-start"
					sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					font="--lead"
					display="flex"
					color="--dark"
					quarkly-title="CompanyName"
					sm-text-align="center"
					md-font="--headline2"
					lg-font="--headline2"
					margin="0px 0px 0px 0px"
					width="350px"
					text-align="left"
					align-items="center"
					flex-direction="row"
					padding="0px 0px 0px 10px"
				>
					JaouadPanorama, LLC
				</Text>
				<Image
					src="https://uploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13:53:24.519Z"
					width="43px"
					z-index="3"
					srcSet="https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
				<Override
					slot="menu"
					lg-transform="translateY(0px) translateX(0px)"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-left={0}
					md-top={0}
					padding="0px 0 0px 0"
					justify-content="center"
					md-width="100%"
					md-height="100%"
					align-items="center"
				>
					<Override
						slot="item"
						text-transform="uppercase"
						text-align="center"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						transition="opacity .15s ease 0s"
						md-color="--dark"
						md-opacity=".5"
						md-hover-opacity="1"
						md-active-opacity="1"
						color="--dark"
						opacity=".5"
						font="--base"
						letter-spacing="0.5px"
						text-transform="initial"
						md-transition="opacity .15s ease 0s"
						hover-opacity="1"
						md-font="16px/24px sans-serif"
						text-decoration-line="initial"
					/>
					<Override
						slot="link-active"
						md-opacity="1"
						md-cursor="default"
						opacity="1"
						color="--primary"
						cursor="default"
					/>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
				<Override
					slot="icon"
					size="36px"
					md-right="0px"
					md-position="relative"
					category="md"
					icon={MdMenu}
				/>
				<Override
					slot="menu-open"
					md-top={0}
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
				/>
				<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
			</Components.BurgerMenu>
			<Button
				white-space="nowrap"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				padding="8px 18px 8px 18px"
				background="--color-primary"
				z-index="5"
				md-display="none"
				border-radius="0px"
				hover-transform="translateY(-4px)"
				justify-self="end"
				font="--base"
				letter-spacing="0.5px"
				href="/contact/#contactSectionForm"
				type="link"
				text-decoration-line="initial"
			>
				Contact Us
			</Button>
		</Section>
		<Section quarkly-title="Hero" padding="25px 0 75px 0" lg-padding="25px 0 25px 0" justify-content="center">
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Box
				align-items="center"
				display="flex"
				justify-content="center"
				background="radial-gradient(at center,--color-darkL2 23.3%,rgba(0,0,0,0) 82.4%),linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,--color-darkL2"
				md-min-height="360px"
				sm-min-height="180px"
				width="100%"
				min-height="480px"
			>
				<Text
					font="--headline1"
					margin="0px 0px 0px 0px"
					quarkly-title="Title"
					sm-text-align="center"
					text-align="center"
					md-font="--headline2"
					display="flex"
					align-items="center"
					color="--light"
					justify-content="center"
					sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					lg-font="--headline2"
				>
					About Us
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0px 100px 0px"
			background="--color-lightD1"
			border-style="solid"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			quarkly-title="About"
			box-sizing="border-box"
			border-width="1px 0px 1px 0px"
			md-padding="50px 0px 50px 0px"
			sm-padding="0px 0 0px 0"
			border-color="--color-lightD2"
		>
			<Override
				slot="SectionContent"
				sm-padding="24px 0px 24px 0px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				align-items="center"
				margin="0px 32px 0px 32px"
			/>
			<Text
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				lg-text-align="center"
				font="--base"
				color="--dark"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				text-transform="uppercase"
				text-align="center"
			>
				Who we are
			</Text>
			<Text
				letter-spacing="1px"
				color="--dark"
				text-align="center"
				width="85%"
				lg-font="--lead"
				sm-font="--base"
				font="--headline3"
				margin="0px 0px 0px 0px"
			>
				We’re a group of creatives who've built a business to make the world a better place.
			</Text>
		</Section>
		<Section
			box-sizing="border-box"
			padding="100px 0px 100px 0px"
			quarkly-title="Our Services"
			xl-padding="100px 0px 100px 0px"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				align-items="center"
			/>
			<Text
				letter-spacing="1px"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="SubTitle"
				margin="0px 0px 10px 0px"
				color="--dark"
				opacity="0.6"
				text-align="center"
				text-transform="uppercase"
				lg-text-align="center"
				font="--base"
			>
				WHAT WE DO BEST
			</Text>
			<Text
				lg-margin="0px 0px 48px 0px"
				md-font="--headline3"
				sm-margin="0px 0px 36px 0px"
				font="--headline2"
				margin="0px 0px 72px 0px"
				color="--dark"
				text-align="center"
				lg-text-align="center"
				quarkly-title="Title"
			>
				Our Services
			</Text>
			<Box
				grid-template-columns="repeat(3, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				width="100%"
				display="grid"
				grid-gap="32px"
				quarkly-title="ColumnList"
			>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
					<Icon
						category="fa"
						icon={FaMountain}
						size="82px"
						margin="0px 0px 19px 0px"
						font="36px sans-serif"
						color="--primary"
					/>
					<Text
						color="--darkL2"
						font="--lead"
						margin="0px 0px 10px 0px"
						display="inline-block"
						quarkly-title="Title"
					>
						Geological Surveys & NI 43-101 Reporting
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						quarkly-title="Description"
					>
						We conduct comprehensive geological surveys to assess terrain, mineral deposits, and geological characteristics. Our expertise also includes assisting mining companies in preparing NI 43-101 reports, ensuring detailed and compliant documentation for accurate project evaluation and investor confidence.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
					<Icon
						category="fa"
						icon={FaIndustry}
						size="82px"
						margin="0px 0px 19px 0px"
						font="36px sans-serif"
						color="--primary"
					/>
					<Text
						font="--lead"
						margin="0px 0px 10px 0px"
						display="inline-block"
						color="--darkL2"
						quarkly-title="Title"
					>
						Mining Optimization
					</Text>
					<Text
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						quarkly-title="Description"
					>
						Optimize mining operations through advanced techniques, maximizing 
resource extraction, and minimizing environmental impact, ensuring 
sustainable and efficient practices.
					</Text>
				</Box>
				<Box justify-content="center" display="flex" flex-direction="column" align-items="center">
					<Icon
						font="36px sans-serif"
						color="--primary"
						category="fa"
						icon={FaLeaf}
						size="82px"
						margin="0px 0px 19px 0px"
					/>
					<Text
						font="--lead"
						margin="0px 0px 10px 0px"
						display="inline-block"
						color="--darkL2"
						quarkly-title="Title"
					>
						Environmental Impact Assessment
					</Text>
					<Text
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						quarkly-title="Description"
					>
						Perform thorough environmental impact assessments to evaluate and 
mitigate the ecological effects of mining and geology-related 
activities, ensuring compliance with regulations.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
					<Icon
						category="fa"
						icon={FaGlobe}
						size="82px"
						margin="0px 0px 19px 0px"
						font="36px sans-serif"
						color="--primary"
					/>
					<Text
						font="--lead"
						margin="0px 0px 10px 0px"
						display="inline-block"
						color="--darkL2"
						quarkly-title="Title"
					>
						GIS and Remote Sensing
					</Text>
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
						quarkly-title="Description"
					>
						Utilize Geographic Information Systems (GIS) and remote sensing 
technologies for precise mapping, data analysis, and decision-making, 
enhancing project planning and execution.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
					<Icon
						font="36px sans-serif"
						color="--primary"
						category="fa"
						icon={FaTint}
						size="82px"
						margin="0px 0px 19px 0px"
					/>
					<Text
						color="--darkL2"
						font="--lead"
						margin="0px 0px 10px 0px"
						display="inline-block"
						quarkly-title="Title"
					>
						Polymetallic Bar Transformation
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
						font="--base"
						quarkly-title="Description"
					>
						Conduct hydrogeological studies to assess groundwater resources, manage 
water-related risks, and ensure the sustainable use of water in geology 
and mining operations.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
					<Icon
						color="--primary"
						category="fa"
						icon={FaLightbulb}
						size="82px"
						margin="0px 0px 19px 0px"
						font="36px sans-serif"
					/>
					<Text
						font="--lead"
						margin="0px 0px 10px 0px"
						display="inline-block"
						color="--darkL2"
						quarkly-title="Title"
					>
						Digitalization Services
					</Text>
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
						quarkly-title="Description"
					>
						We specialize in the digital transformation of mining operations, offering solutions like data analytics, automation, and digital twin technology to streamline processes and enhance productivity.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="80px 0px 80px 0px"
			quarkly-title="About"
			background="--color-lightD1"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			box-sizing="border-box"
			border-width="1px 0px 1px 0px"
			border-style="solid"
			border-color="--color-lightD2"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				grid-gap="32px"
				grid-template-columns="repeat(3, 1fr)"
				width="100%"
				lg-grid-template-columns="1fr"
				display="grid"
			>
				<Box
					grid-gap="32px"
					grid-template-columns="repeat(3, 1fr)"
					width="100%"
					lg-grid-template-columns="1fr"
					display="inline-block"
				>
					<Text
						margin="0px 0px 16px 0px"
						display="grid"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="left"
						font="--lead"
						quarkly-title="Title"
						width="100%"
						overflow-x="visible"
						align-items="end"
						justify-items="center"
					>
						Our History & Heritage
					</Text>
					<Text
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="left"
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						quarkly-title="History"
					>
						JPLLC was founded by a group of passionate technologists and geoscientists with a shared vision of responsibly exploring Earth's geological wealth. Our journey began with a focus on comprehensive geological surveys and has evolved to include advanced digitalization services. Our rich history is built on innovation, expertise, and a commitment to shaping the future of mining.
					</Text>
				</Box>
				<Box
					grid-gap="32px"
					grid-template-columns="repeat(3, 1fr)"
					width="100%"
					lg-grid-template-columns="1fr"
					display="inline-block"
				>
					<Text
						margin="0px 0px 16px 0px"
						display="grid"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="left"
						font="--lead"
						quarkly-title="Title"
						width="100%"
						overflow-x="visible"
						align-items="end"
						justify-items="center"
					>
						Our Commitment to Excellence
					</Text>
					<Text
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="left"
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						quarkly-title="Commitment"
					>
						At JPLLC, excellence is at the core of everything we do. We are dedicated to providing cutting-edge solutions, from GIS and remote sensing to mining optimization and environmental assessments. Our team of experts ensures that every project is executed with precision, integrity, and a relentless pursuit of quality.
					</Text>
				</Box>
				<Box
					grid-gap="32px"
					grid-template-columns="repeat(3, 1fr)"
					width="100%"
					lg-grid-template-columns="1fr"
					display="inline-block"
				>
					<Text
						margin="0px 0px 16px 0px"
						display="grid"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="left"
						font="--lead"
						quarkly-title="Title"
						width="100%"
						overflow-x="visible"
						align-items="end"
						justify-items="center"
					>
						Our Vision for the Future
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="left"
						text-align="center"
						font="--base"
						quarkly-title="Vision"
					>
						Our vision for the future is to lead the mining industry through digital transformation and sustainable practices. We aim to revolutionize operations with advanced technologies. We are committed to driving innovation, enhancing efficiency, and promoting a sustainable approach to resource management.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="75px 0 50px 0"
			lg-padding="50px 0 25px 0"
			lg-height="auto"
			justify-content="center"
			quarkly-title="USP"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				min-height="480px"
				display="flex"
				justify-content="center"
				background="linear-gradient(180deg,rgba(24, 29, 34, 0.5) 0%,transparent 100%) 0 0 no-repeat,--color-lightD1 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-fireworks.svg?v=2020-11-06T17:22:27.801Z) center center/cover no-repeat"
				lg-min-height="420px"
				sm-min-height="280px"
				padding="36px 24px 36px 24px"
				align-items="center"
				flex-direction="column"
				md-min-height="360px"
			>
				<Text
					margin="10px 0px 15px 0px"
					font="--headline2"
					text-align="center"
					quarkly-title="Title"
					color="--light"
					md-font="--headline3"
				>
					Make It Work.
				</Text>
				<Text
					margin="0px 0px 28px 0px"
					font="--lead"
					opacity="0.6"
					text-align="center"
					quarkly-title="Description"
					color="--light"
					max-width="720px"
				>
					Unearthing Solutions, Sustaining Success, Digitalizing the world
				</Text>
				<Button
					letter-spacing="0.5px"
					transition="opacity .15s ease 0s"
					z-index="5"
					margin="10px auto 0px auto"
					padding="8px 36px 8px 36px"
					position="relative"
					opacity="1"
					hover-opacity=".85"
					hover-box-shadow="none"
					active-box-shadow="none"
					width="fit-content"
					font="--lead"
					focus-box-shadow="none"
					md-font="--base"
					color="--darkL2"
					background="--color-light"
					href="/contact/#contactSectionForm"
					type="link"
					text-decoration-line="initial"
				>
					Start Now
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
	</Theme>;
});