import React from "react";
import theme from "theme";
import { Theme, Link, Text, Image, Button, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"work"} />
		<Helmet>
			<title>
				Work | Website Example
			</title>
			<meta name={"description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:title"} content={"Work | Website Example"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13:53:24.519Z"} type={"image/x-icon"} />
		</Helmet>
		<Section align-items="center" justify-content="center" padding="16px 0 16px 0" quarkly-title="NavHeader">
			<Override
				slot="SectionContent"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				display="grid"
			/>
			<Link
				href="/"
				position="relative"
				transition="opacity 200ms ease"
				quarkly-title="Link"
				display="flex"
				flex-direction="row-reverse"
				text-align="left"
			>
				<Text
					justify-content="flex-start"
					sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					font="--lead"
					display="flex"
					color="--dark"
					quarkly-title="CompanyName"
					sm-text-align="center"
					md-font="--headline2"
					lg-font="--headline2"
					margin="0px 0px 0px 0px"
					width="350px"
					text-align="left"
					align-items="center"
					flex-direction="row"
					padding="0px 0px 0px 10px"
				>
					JaouadPanorama, LLC
				</Text>
				<Image
					src="https://uploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13:53:24.519Z"
					width="43px"
					z-index="3"
					srcSet="https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/JaouadPanorama_logo_noback.png?v=2023-09-17T13%3A53%3A24.519Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
				<Override
					slot="menu"
					lg-transform="translateY(0px) translateX(0px)"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-left={0}
					md-top={0}
					padding="0px 0 0px 0"
					justify-content="center"
					md-width="100%"
					md-height="100%"
					align-items="center"
				>
					<Override
						slot="item"
						text-transform="uppercase"
						text-align="center"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						transition="opacity .15s ease 0s"
						md-color="--dark"
						md-opacity=".5"
						md-hover-opacity="1"
						md-active-opacity="1"
						color="--dark"
						opacity=".5"
						font="--base"
						letter-spacing="0.5px"
						text-transform="initial"
						md-transition="opacity .15s ease 0s"
						hover-opacity="1"
						md-font="16px/24px sans-serif"
						text-decoration-line="initial"
					/>
					<Override
						slot="link-active"
						md-opacity="1"
						md-cursor="default"
						opacity="1"
						color="--primary"
						cursor="default"
					/>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
				<Override
					slot="icon"
					size="36px"
					md-right="0px"
					md-position="relative"
					category="md"
					icon={MdMenu}
				/>
				<Override
					slot="menu-open"
					md-top={0}
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
				/>
				<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
			</Components.BurgerMenu>
			<Button
				white-space="nowrap"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				padding="8px 18px 8px 18px"
				background="--color-primary"
				z-index="5"
				md-display="none"
				border-radius="0px"
				hover-transform="translateY(-4px)"
				justify-self="end"
				font="--base"
				letter-spacing="0.5px"
				href="/contact/#contactSectionForm"
				type="link"
				text-decoration-line="initial"
			>
				Contact Us
			</Button>
		</Section>
		<Section padding="25px 0 75px 0" lg-padding="25px 0 25px 0" justify-content="center" quarkly-title="Hero">
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				sm-min-height="180px"
				width="100%"
				min-height="480px"
				display="flex"
				align-items="center"
				justify-content="center"
				background="radial-gradient(at center,--color-darkL2 23.3%,rgba(0,0,0,0) 82.4%),linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,--color-darkL2"
				md-min-height="360px"
			>
				<Text
					justify-content="center"
					quarkly-title="Title"
					text-align="center"
					sm-text-align="center"
					md-font="--headline2"
					font="--headline1"
					margin="0px 0px 0px 0px"
					display="flex"
					align-items="center"
					color="--light"
					sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					lg-font="--headline2"
				>
					Our Top Services
				</Text>
			</Box>
		</Section>
		<Section
			border-color="--color-lightD2"
			border-style="solid"
			border-width="1px 0px 1px 0px"
			sm-padding="24px 0 24px 0"
			quarkly-title="About"
			box-sizing="border-box"
			background="--color-lightD1"
			padding="90px 0px 100px 0px"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Text
				color="--dark"
				lg-text-align="center"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				text-transform="uppercase"
				text-align="center"
				font="--base"
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
			>
				OUR services
			</Text>
			<Text
				text-align="center"
				width="85%"
				lg-font="--lead"
				sm-font="--base"
				font="--headline3"
				margin="0px 0px 0px 0px"
				letter-spacing="1px"
				color="--dark"
			>
				Empowering Your Success through Precision Geology, Innovative Mining Solutions, and Sustainable Environmental Practices.
			</Text>
		</Section>
		<Section lg-padding="60px 0 60px 0" quarkly-title="Services" justify-content="center" padding="100px 0 80px 0">
			<Override
				slot="SectionContent"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				margin="0px 32px 0px 32px"
			/>
			<Box
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				lg-grid-template-columns="1fr"
				display="grid"
				quarkly-title="GeoSurvey"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						margin="0px 0px 10px 0px"
						color="--dark"
						opacity="0.6"
						lg-text-align="center"
						text-transform="uppercase"
						font="--base"
						letter-spacing="1px"
						lg-margin="0px 0px 6px 0px"
						quarkly-title="Title"
					>
						survey
					</Text>
					<Text
						color="--dark"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
					>
						Specilized Geo Surveys & NI 43-101 Reporting
					</Text>
					<Text
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="left"
					>
						We provide specialized geological surveys focused on NI 43-101 compliance, delivering precise assessments of terrain and mineral deposits. Our tailored approach ensures comprehensive and compliant documentation, offering critical insights for project evaluation and investor confidence.
					</Text>
					<Link
						margin="9px 0px 0px 0px"
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						color="--light"
						padding="8px 18px 8px 18px"
						font="--base"
						letter-spacing="0.5px"
						text-align="center"
						href="/contact/#contactSectionForm"
						text-decoration-line="initial"
						background="--color-primary"
					>
						I Want to know more!
					</Link>
				</Box>
				<Box
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					background="url(https://uploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/geosurvey.jpg?v=2023-10-01T02:34:09.753Z) 0% 0%/cover"
					margin="0px 0px 0px 0px"
					padding="0px 0px 60% 0px"
					hover-background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-first.svg?v=2020-11-06T16:37:39.391Z) center center/110% no-repeat"
					min-height="auto"
					max-height="100%"
					height="0px"
				/>
			</Box>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				margin="92px 0px 0px 0px"
				lg-grid-template-columns="1fr"
				lg-margin="56px 0px 0px 0px"
				quarkly-title="SmartMining"
				order="0"
				flex="1 0"
				align-self="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					overflow-x="visible"
				>
					<Text
						quarkly-title="Title"
						font="--base"
						color="--dark"
						lg-margin="0px 0px 6px 0px"
						lg-text-align="center"
						margin="0px 0px 10px 0px"
						letter-spacing="1px"
						opacity="0.6"
						text-transform="uppercase"
					>
						mining
					</Text>
					<Text
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
						md-font="--headline3"
						font="--headline2"
					>
						Smart-Mining
					</Text>
					<Text
						opacity="0.6"
						lg-text-align="left"
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
					>
						Unlock the full potential of your mining operations with our Smart 
Mining Optimization service. We leverage advanced technologies and data 
analytics to maximize resource extraction, minimize environmental 
impact, and enhance operational efficiency. Stay ahead in the industry 
with our cutting-edge solutions for sustainable and profitable mining.
					</Text>
					<Link
						margin="9px 0px 0px 0px"
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						color="--light"
						padding="8px 18px 8px 18px"
						font="--base"
						letter-spacing="0.5px"
						text-align="center"
						href="/contact/#contactSectionForm"
						text-decoration-line="initial"
						background="--color-primary"
					>
						I Want to know more!
					</Link>
				</Box>
				<Box
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					margin="0px 0px 0px 0px"
					padding="0px 0px 60% 0px"
					height="0px"
					hover-background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-second.svg?v=2020-11-06T17:14:59.136Z) center center/110% no-repeat"
					background="url(https://uploads.quarkly.io/65060e7a6efcea001ae6f2dc/images/smartmining.jpg?v=2023-10-01T02:53:29.605Z) 0% 0%/cover,--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-second.svg?v=2020-11-06T17:14:59.136Z) center center/100% no-repeat"
				/>
			</Box>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				margin="92px 0px 0px 0px"
				lg-grid-template-columns="1fr"
				lg-margin="56px 0px 0px 0px"
				quarkly-title="EnvironmentImpact"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						letter-spacing="1px"
						opacity="0.6"
						quarkly-title="Title"
						lg-margin="0px 0px 6px 0px"
						text-transform="uppercase"
						font="--base"
						margin="0px 0px 10px 0px"
						color="--dark"
						lg-text-align="center"
					>
						impact
					</Text>
					<Text
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
					>
						Environmental Impact
					</Text>
					<Text
						lg-text-align="left"
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
					>
						Ensure regulatory compliance and sustainable practices with our 
Comprehensive Environmental Impact Assessment service. We meticulously 
assess the ecological effects of your geology and mining activities, 
providing detailed insights and actionable recommendations. Let us guide
 you in minimizing environmental impact while maximizing operational 
success.
					</Text>
					<Link
						margin="9px 0px 0px 0px"
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						color="--light"
						padding="8px 18px 8px 18px"
						font="--base"
						letter-spacing="0.5px"
						text-align="center"
						href="/contact/#contactSectionForm"
						text-decoration-line="initial"
						background="--color-primary"
					>
						I Want to know more!
					</Link>
				</Box>
				<Box
					background="url(https://images.unsplash.com/photo-1500354960738-4c480ed785bc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0%/cover,--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-third.svg?v=2020-11-06T17:15:50.700Z) center center/100% no-repeat"
					margin="0px 0px 0px 0px"
					padding="0px 0px 60% 0px"
					height="0px"
					hover-background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-third.svg?v=2020-11-06T17:15:50.700Z) center center/110% no-repeat"
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
				/>
			</Box>
		</Section>
		<Section
			box-sizing="border-box"
			quarkly-title="About"
			background="--color-lightD1"
			border-style="solid"
			border-color="--color-lightD2"
			lg-padding="50px 0px 50px 0px"
			padding="80px 0px 80px 0px"
			border-width="1px 0px 1px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				display="grid"
				grid-template-columns="1fr 2fr"
				lg-grid-template-columns="1fr"
				grid-gap="32px"
				width="100%"
			>
				<Text
					color="--darkL2"
					lg-margin="0px 0px 0px 0px"
					font="--headline3"
					margin="0px 0px 10px 0px"
					display="inline-block"
					position="static"
					align-self="center"
				>
					Our Commitment to You
				</Text>
				<Text
					display="inline-block"
					opacity="0.6"
					color="--darkL2"
					lg-text-align="left"
					text-align="left"
					font="--base"
					margin="0px 0px 0px 0px"
				>
					At JaouadPanorama, LLC, our commitment to excellence goes beyond services. We are dedicated to understanding your unique challenges and delivering tailored solutions. With a focus on innovation, sustainability, and client satisfaction, we strive to be your trusted partner in every project. Your success is our priority, and we look forward to the opportunity to exceed your expectations.
				</Text>
			</Box>
		</Section>
		<Section
			padding="75px 0 50px 0"
			lg-padding="50px 0 25px 0"
			lg-height="auto"
			justify-content="center"
			quarkly-title="USP"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				flex-direction="column"
				lg-min-height="420px"
				sm-min-height="280px"
				min-height="480px"
				padding="36px 24px 36px 24px"
				display="flex"
				align-items="center"
				justify-content="center"
				background="linear-gradient(180deg,rgba(24, 29, 34, 0.5) 0%,transparent 100%) 0 0 no-repeat,--color-lightD1 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-fireworks.svg?v=2020-11-06T17:22:27.801Z) center center/cover no-repeat"
				md-min-height="360px"
			>
				<Text
					font="--headline2"
					text-align="center"
					quarkly-title="Title"
					color="--light"
					md-font="--headline3"
					margin="10px 0px 15px 0px"
				>
					Make It Work.
				</Text>
				<Text
					margin="0px 0px 28px 0px"
					font="--lead"
					opacity="0.6"
					text-align="center"
					quarkly-title="Description"
					color="--light"
					max-width="720px"
				>
					Unearthing Solutions, Sustaining Success, Digitalizing the world
				</Text>
				<Button
					margin="10px auto 0px auto"
					width="fit-content"
					color="--darkL2"
					transition="opacity .15s ease 0s"
					z-index="5"
					active-box-shadow="none"
					background="--color-light"
					opacity="1"
					md-font="--base"
					padding="8px 36px 8px 36px"
					letter-spacing="0.5px"
					position="relative"
					hover-box-shadow="none"
					font="--lead"
					hover-opacity=".85"
					focus-box-shadow="none"
					href="/contact/#contactSectionForm"
					type="link"
					text-decoration-line="initial"
				>
					Start Now
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
	</Theme>;
});